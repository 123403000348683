import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Get the query parameter 'user' from the URL
    const params = new URLSearchParams(window.location.search);
    const user = params.get('user');

    if (user) {
      // Redirect to the new URL with the user ID
      window.location.href = `https://u.tebsocial.com/${user}`;
    }
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  );
}

export default App;